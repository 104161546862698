import { atom } from "recoil";
import { TerminalPricingResponse } from "../data/dataTerminalPricing";
import { Country } from "../model/common/commonType";
import { TerminalType } from "../model/contract/contractType";

export type TerminalPricingState = Record<TerminalType, TerminalPricing>;
export interface TerminalPricing {
  monthlyCost: number | undefined;
  oneTimeCost: number | undefined;
}

// TODO: create different default state depending on country
export const DEFAULT_TERMINAL_PRICING_STATE: TerminalPricingState = {
  [TerminalType.DESK_3500]: {
    monthlyCost: undefined,
    oneTimeCost: undefined,
  },
  [TerminalType.MOVE_3500]: {
    monthlyCost: undefined,
    oneTimeCost: undefined,
  },
  [TerminalType.SOFT_POS]: {
    monthlyCost: undefined,
    oneTimeCost: undefined,
  },
  [TerminalType.DESK_5000]: {
    monthlyCost: undefined,
    oneTimeCost: undefined,
  },
  [TerminalType.MOVE_5000]: {
    monthlyCost: undefined,
    oneTimeCost: undefined,
  },
  [TerminalType.SATURN]: {
    monthlyCost: undefined,
    oneTimeCost: undefined,
  },
  [TerminalType.LINK_2500]: {
    monthlyCost: undefined,
    oneTimeCost: undefined,
  },
  [TerminalType.DX_8000]: {
    monthlyCost: undefined,
    oneTimeCost: undefined,
  },
  [TerminalType.ECOMMERCE]: {
    monthlyCost: undefined,
    oneTimeCost: undefined,
  },
  [TerminalType.EX_8000]: {
    monthlyCost: undefined,
    oneTimeCost: undefined,
  },
};

export const OLD_TERMINAL_PRICES = {
  [TerminalType.DESK_3500]: {
    monthlyCost: 39,
    oneTimeCost: 0,
  },
  [TerminalType.MOVE_3500]: {
    monthlyCost: 45,
    oneTimeCost: 0,
  },
  [TerminalType.SOFT_POS]: {
    monthlyCost: 6.75,
    oneTimeCost: 0,
  },
  [TerminalType.DESK_5000]: {
    monthlyCost: 39,
    oneTimeCost: 0,
  },
  [TerminalType.MOVE_5000]: {
    monthlyCost: 39,
    oneTimeCost: 0,
  },
  [TerminalType.SATURN]: {
    monthlyCost: 39,
    oneTimeCost: 0,
  },
  [TerminalType.LINK_2500]: {
    monthlyCost: 39,
    oneTimeCost: 0,
  },
  [TerminalType.DX_8000]: {
    monthlyCost: 39,
    oneTimeCost: 0,
  },
  [TerminalType.EX_8000]: {
    monthlyCost: 15,
    oneTimeCost: 0,
  },
  [TerminalType.ECOMMERCE]: {
    monthlyCost: 30,
    oneTimeCost: 0,
  },
};

// modify later if the defaults are different for countries
export const getDefaultTerminalPricingState = (country: Country): TerminalPricingState => {
  switch (country) {
    case Country.POLAND:
      return DEFAULT_TERMINAL_PRICING_STATE;
    case Country.CROATIA:
      return DEFAULT_TERMINAL_PRICING_STATE;
    case Country.CZECHIA:
      return DEFAULT_TERMINAL_PRICING_STATE;
    default:
      return DEFAULT_TERMINAL_PRICING_STATE;
  }
};

export const convertTerminalPricingResponseToState = (
  response: TerminalPricingResponse[],
  country: Country
): TerminalPricingState => {
  const state: TerminalPricingState = {
    ...getDefaultTerminalPricingState(country),
  };
  response.forEach((pricingResponse) => {
    const { terminalType } = pricingResponse;
    state[terminalType] = toPricing(pricingResponse);
  });
  return state;
};

const toPricing = (terminalPricingResponse: TerminalPricingResponse): TerminalPricing => {
  const { monthlyCost, oneTimeCost } = terminalPricingResponse;
  return { monthlyCost, oneTimeCost };
};

// state for what price for each terminal is selected
export const terminalPricingState = atom<TerminalPricingState>({
  key: "terminal-pricing",
  default: DEFAULT_TERMINAL_PRICING_STATE,
});
